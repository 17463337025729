
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';
import { QSkeleton } from 'quasar';

import WsButton from '@WS_Components/WsButton.vue';
import WsMessage from '@WS_Components/WsMessage.vue';
import { Debounce, Throttle } from '@loopia-group/utils';

import { safePush } from '@/router';
import { AppState } from '@/store/const';
import { logError } from '@/services/logger.service';
import {
  addDomainToCart,
  checkDomains,
  stopRunningCheck,
} from '@/services/domain-checker.service';
import { Domain } from '@/components/domain-checker/dc.types';
import PriceCell from '@/components/domain-checker/resultsTable/PriceCell.vue';
import WsSpinner from '@WS_Components/WsSpinner.vue';

const ALTERNATIVE_DOMAINS_CHUNK_SIZE = 30;
const OFFERED_DOMAINS_SIZE = 4;

@Component({
  components: {
    WsSpinner,
    QSkeleton,
    WsButton,
    WsMessage,
    PriceCell,
  },
  computed: {
    ...mapState<AppState>({
      checkingDomains: (state: AppState) => !!state.domainChecker?.searching,
      endOfStream: (state: AppState) => !!state.domainChecker?.endOfStream,
    }),
    ...mapGetters(['allUserDomains', 'dcAllDomains']),
  },
})
export class XSellAlternativeDomains extends Vue {
  @Prop() readonly model!: string; // expecting trimmed lowercased value
  @Prop() readonly disabled!: boolean;
  // how many results from beggining are skipped
  // (because parent displays them in a different way by itself)
  @Prop({ type: Number, default: 0 }) readonly skip!: number;
  // helper skip value to handle changes of skip, but not to change domains under cursor if not needed
  helperSkip = 0;
  readonly allUserDomains!: string[];
  readonly dcAllDomains!: Domain[];
  readonly checkingDomains!: boolean;
  readonly endOfStream!: boolean;
  addingToCart = new Set<string>();
  path = 'xsellAlternativeDomains';
  skeletonDomains: {}[] = []; // fake array to hold animated skeletons while transitioning the state
  domains: (Domain | {})[] = []; // xsell offered/displayed domains
  alternativeDomainsPage!: number;
  addedDomainCounter!: number;
  // flashing removal feature ref: https://loopiagroup.atlassian.net/browse/PNO-2813
  isVisibleDebounced = false;

  readonly mobile!: boolean;
  readonly tablet!: boolean;
  readonly desktop!: boolean;

  public get isVisible(): boolean {
    return !!(this.checkingDomains || this.domains.length);
  }

  @Watch('isVisible', { immediate: true })
  @Debounce(1000)
  isVisibleChanged(isVisible: boolean) {
    this.isVisibleDebounced = isVisible;
  }

  get colWidth(): number {
    const colWidth = 12 / (this.domains.length || OFFERED_DOMAINS_SIZE);
    // no more than size of 6 for col and no col-5
    return colWidth > 6 || colWidth === 5 ? 6 : colWidth;
  }

  @Watch('skip', { immediate: true })
  onSkipChange(skip: number) {
    if (skip > this.helperSkip) {
      this.helperSkip = skip;
      this.domains = []; // reset domains
      this.recalculateDomains();
    }
  }

  @Watch('model', { immediate: true })
  onModelChange() {
    this.reset();
  }

  created() {
    this.skeletonDomains = Array(OFFERED_DOMAINS_SIZE).fill({});
  }

  get domainsTlds(): string[] {
    return this.domains.filter(d => 'tld' in d).map(d => (d as Domain).tld);
  }

  get domainsStringified() {
    return JSON.stringify({
      checkingDomains: this.checkingDomains,
      alternativeDomains: this.alternativeDomains,
    });
  }

  // flashing removal feature ref: https://loopiagroup.atlassian.net/browse/PNO-2813
  @Watch('domainsStringified')
  @Debounce(500)
  recalculateDomains() {
    let tmpDomains: (Domain | {})[] = [...this.domains];
    tmpDomains = this.prepareTmpDomains(tmpDomains);

    const availableAlternatives = [...this.alternativeDomains].filter(
      d => !this.domainsTlds.includes(d.tld)
    );
    if (
      !this.checkingDomains &&
      availableAlternatives.length < ALTERNATIVE_DOMAINS_CHUNK_SIZE
    ) {
      // running out of alternatives, load next page/chunk
      this.startCheck(this.model, this.alternativeDomainsPage++);
    }

    const size = OFFERED_DOMAINS_SIZE;
    for (let i = 0; i < size; i++) {
      const domain = tmpDomains[i];

      if (!(domain as Domain)?.tld || (domain as Domain)?.domainAlreadyInCart) {
        tmpDomains[i] = availableAlternatives.length
          ? availableAlternatives.shift()!
          : {};
      }
    }

    if (!this.checkingDomains) {
      // clean placeholders/loaders
      tmpDomains = tmpDomains.filter(d => 'tld' in d);
    }

    this.domains = tmpDomains;
  }

  private prepareTmpDomains(tmpDomains: (| {})[]) {
    if (!this.alternativeDomains.length && this.checkingDomains) {
      tmpDomains = [];
      let size = OFFERED_DOMAINS_SIZE;
      while (size--) {
        // fill in empty object to keep positions with loader
        tmpDomains.push({});
      }
    }
    return tmpDomains;
  }

  get alternativeDomains(): Domain[] {
    let alternatives: Domain[] = [];

    if (!this.model || !this.dcAllDomains.length) {
      alternatives = [];
    } else {
      const filteredDomains = this.dcAllDomains.filter(
        domain =>
          domain.availableForPurchase &&
          !domain.domainAlreadyInCart &&
          domain.name !== this.model
      );
      alternatives = [...filteredDomains].slice(this.helperSkip);

      const skipDiff = this.helperSkip - this.skip;
      if (skipDiff > 0) {
        const skipped = filteredDomains.splice(0, this.helperSkip);
        alternatives.splice(
          OFFERED_DOMAINS_SIZE + this.addedDomainCounter,
          0,
          ...skipped
        );
      }
    }

    return alternatives;
  }

  @Throttle()
  startCheck(model: string, page = 1) {
    if (!model || this.checkingDomains || (this.endOfStream && page > 1)) {
      return;
    }
    checkDomains(
      // do not start new search if model is the same, just continue with additional results
      page === 1 && this.dcAllDomains?.[0]?.name !== this.model,
      [model],
      page,
      ALTERNATIVE_DOMAINS_CHUNK_SIZE
    ).catch((error: any) => {
      if (error === 'ws-abort') {
        this.reset();
      } else {
        logError(error);
      }
    });
  }

  buy(domain: Domain) {
    if (
      domain.domainAlreadyInCart ||
      this.addingToCart.has(domain.tld) ||
      this.disabled
    ) {
      return;
    }
    this.addingToCart.add(domain.tld);

    // force to see loader, yes I have tried to replace Set by Object literal, did not fix issue ¯\_(ツ)_/¯
    this.$forceUpdate();

    addDomainToCart(domain, { sellType: 'x-sell' })
      .then(() => {
        domain.domainAlreadyInCart = true;
        // manually trigger because array is modified and not deepwatched
        this.recalculateDomains();
      })
      .catch(this.$messageService.errorHandler(this.path))
      .finally(() => {
        this.addingToCart.delete(domain.tld);
        this.$emit('alternative-domain-bought', domain);
      });
  }

  clearMessages() {
    this.$messageService.clearMessages(this.path);
  }

  goToDomainChecker() {
    safePush(`/domain-checker?qd=${this.model}`);
  }

  reset() {
    this.alternativeDomainsPage = 1;
    this.addedDomainCounter = 0;
    this.domains = [];
    stopRunningCheck();
    this.addingToCart.clear();
  }

  destroyed() {
    this.reset();
  }
}
export default XSellAlternativeDomains;
