
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

import {QField} from 'quasar';

import {getSnakedKeyFactory, redirect} from '@loopia-group/utils';
import {required} from '@WS_UIkit';
import WsIcon from '@WS_Components/WsIcon.vue';
import WsButton from '@WS_Components/WsButton.vue';
import WsInput from '@WS_Components/WsInput.vue';
import WsSpinner from '@WS_Components/WsSpinner.vue';
import {getLoginUrl} from '@/utilities';
import config from '@/services/config.service';
import {SuggestionMessage} from '@/components/optionalStep/optionalStep.types.d';
import {OPTIONAL_STEP_CONTENT} from './const.enum';
import {ROUTENAMES} from '@/const.enum';
import caryWithDomains from '@/assets/images/Cary/cary-with-domains.png';

@Component({
  components: {
    QField,
    WsIcon,
    WsButton,
    WsInput,
    WsSpinner,
  },
})
export default class OptionalStepMessage extends Vue {
  @Prop({ required: true })
  readonly message!: SuggestionMessage;
  @Prop(Boolean)
  readonly loading!: boolean;

  showTransfer = false;
  supportUrl = config.supportUrl;
  caryWithDomains = caryWithDomains;
  OPTIONAL_STEP_CONTENT = OPTIONAL_STEP_CONTENT;
  code = '';
  required = required;

  @Watch('code')
  onCodeChanged(code: string) {
    if (!code /*code was ceared */) {
      ((this.$refs.input as WsInput).$refs.qfield as QField).resetValidation();
    }
  }

  goToLogin() {
    redirect(getLoginUrl(this.$router, ROUTENAMES.OPTIONAL));
  }

  getKey(prop: string, key: string): string {
    this.getKey = getSnakedKeyFactory('opt_step.' + this.message.prop);
    return this.getKey(prop, key);
  }
}
