var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"cmp-optional-step-message q-py-md text-center",class:_vm.mobile ? 'q-px-md' : 'q-px-4xl'},[(_vm.message.case === _vm.OPTIONAL_STEP_CONTENT.MESSAGE_HINT)?_c('div',{staticClass:"message-hint"},[_c('img',{staticClass:"cary-with-domains q-mb-md q-mt-3xl q-mx-auto",attrs:{"src":_vm.caryWithDomains,"alt":"?"}}),_c('h6',{staticClass:"text-ws-base150 q-mt-xs q-mb-xl"},[_vm._v(" "+_vm._s(_vm.$t(_vm.getKey('msg')))+" ")]),_c('div',{staticClass:"link-wrapper q-mb-3xl row items-center justify-center"},[_c('a',{staticClass:"ws-discreet-link",attrs:{"href":_vm.supportUrl,"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_c('ws-icon',{staticClass:"q-mr-sm",attrs:{"name":"info"}}),_c('small',[_vm._v(_vm._s(_vm.$t(_vm.getKey('link'))))])],1)])]):(_vm.message.case === _vm.OPTIONAL_STEP_CONTENT.MESSAGE_CHECKING)?_c('div',{staticClass:"q-mb-5xl"},[_c('div',{staticClass:"loader-wrapper q-mt-4xl"},[_c('ws-spinner',{attrs:{"width":"64px","height":"24.4px"}})],1),_c('p',{staticClass:"text-ws-base150 text-bold q-mt-sm ws-typo-button-sm"},[_vm._v(" "+_vm._s(_vm.$t('opt_step.checking_domain'))+" ")])]):(
      [
        _vm.OPTIONAL_STEP_CONTENT.MESSAGE_TRANSFER,
        _vm.OPTIONAL_STEP_CONTENT.MESSAGE_OCCUPIED,
        _vm.OPTIONAL_STEP_CONTENT.DOMAIN_NOT_OFFERED,
        _vm.OPTIONAL_STEP_CONTENT.MESSAGE_HAS_SERVICE,
        _vm.OPTIONAL_STEP_CONTENT.MESSAGE_IN_WS_LOGGED_OUT,
        _vm.OPTIONAL_STEP_CONTENT.MESSAGE_HAS_BUSINESS_MAIL,
        _vm.OPTIONAL_STEP_CONTENT.MESSAGE_CURRENT_USER_IS_NOT_THE_OWNER
      ].includes(_vm.message.case)
    )?_c('div',{staticClass:"message-wrapper q-mx-auto",class:_vm.mobile ? 'q-mb-xl' : 'q-mb-3xl'},[_c('h5',{staticClass:"title q-ma-md",class:{ 'q-mt-xl': !_vm.mobile }},[_vm._v(" "+_vm._s(_vm.$t(_vm.getKey(_vm.message.case, 'title')))+" ")]),_c('small',{staticClass:"inline-block",class:_vm.desktop ? 'q-mb-3xl' : 'q-mb-xl'},[_vm._v(" "+_vm._s(_vm.$t(_vm.getKey(_vm.message.case, 'text')))+" ")]),(_vm.message.case === _vm.OPTIONAL_STEP_CONTENT.MESSAGE_TRANSFER)?[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showTransfer),expression:"!showTransfer"}]},[_c('ws-button',{class:{ 'full-width q-mb-sm': _vm.mobile },attrs:{"primary":"","icon":"transfer"},on:{"click":function($event){_vm.showTransfer = true}}},[_vm._v(" "+_vm._s(_vm.$t('opt_step.domain.message_transfer.transfer.btn'))+" ")]),_c('ws-button',{staticClass:"q-ml-md",class:{ 'full-width': _vm.mobile },attrs:{"secondary":""},on:{"click":function($event){return _vm.$emit('link')}}},[_vm._v(" "+_vm._s(_vm.$t('opt_step.domain.message_transfer.btn_wo_transfer'))+" ")])],1),(_vm.showTransfer)?_c('div',{staticClass:"epp-code-input-wrapper q-mb-xl"},[_c('ws-input',{ref:"input",staticClass:"epp-code-input",attrs:{"messages-overflow":true,"path":"opt_step.epp_code","label":_vm.$t('opt_step.domain.message_transfer.transfer.placeholder'),"required":"","rules":[_vm.required]},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}}),_c('ws-button',{staticClass:"epp-code-button",attrs:{"primary":"","forced-desktop":"","disable":_vm.loading,"loading":_vm.loading,"icon":_vm.mobile ? 'save' : null},on:{"click":function($event){return _vm.$emit('transfer', _vm.code)}}},[(!_vm.mobile)?_c('span',[_vm._v(_vm._s(_vm.$t('opt_step.domain.message_transfer.transfer.btn_save')))]):_vm._e()])],1):_vm._e()]:(
        _vm.message.case === _vm.OPTIONAL_STEP_CONTENT.MESSAGE_IN_WS_LOGGED_OUT
      )?[_c('ws-button',{attrs:{"primary":"","icon":"account"},on:{"click":_vm.goToLogin}},[_vm._v(" "+_vm._s(_vm.$t('opt_step.domain.message_in_ws_logged_out.btn'))+" ")])]:_vm._e()],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }