var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('q-table',{staticClass:"cmp-optional-step-suggestions ws-table full-width",class:{
    'is-table': _vm.desktop || _vm.tablet,
    'is-grid': _vm.mobile,
  },attrs:{"data":_vm.domains,"wrap-cells":"","hide-bottom":"","hide-header":"","grid":_vm.mobile,"columns":_vm.columns,"flat":"","virtual-scroll":!_vm.mobile,"pagination":_vm.pagination,"row-key":"index"},scopedSlots:_vm._u([{key:"body",fn:function(props){return [_c('q-tr',{staticClass:"table-row",attrs:{"props":props},on:{"click":function($event){return _vm.emit(props.row)}}},[_c('q-td',{key:"domain",staticClass:"td-domain",class:{
          ['text-bold']: props.rowIndex === _vm.selected || props.row === _vm.value,
        },attrs:{"props":props}},[_c('div',{staticClass:"wrapper q-pr-sm",domProps:{"innerHTML":_vm._s(_vm.emphasiseQuery(props.row))}})]),_c('q-td',{key:"action",staticClass:"td-action",attrs:{"props":props}},[_c('ws-button',{staticClass:"action-btn",attrs:{"primary":"","icon":"link","disable":_vm.loading,"loading":_vm.loading && (props.rowIndex === _vm.selected || props.row === _vm.value)}},[_vm._v(" "+_vm._s(_vm.$t('opt_step.link_domain'))+" ")])],1)],1)]}},{key:"item",fn:function(props){return [_c('div',{staticClass:"grid-item full-width"},[_c('div',{staticClass:"domain-col text-left",class:{
          ['text-bold']: props.rowIndex === _vm.selected || props.row === _vm.value,
        }},[_c('div',{staticClass:"wrapper q-pr-sm",domProps:{"innerHTML":_vm._s(_vm.emphasiseQuery(props.row))}})]),_c('div',{staticClass:"action-col"},[_c('q-btn',{staticClass:"action-btn",attrs:{"btn":"","flat":"","ripple":false,"disable":_vm.loading,"loading":_vm.loading && (props.rowIndex === _vm.selected || props.row === _vm.value)},on:{"click":function($event){return _vm.emit(props.row)}}},[_c('ws-icon',{staticClass:"q-mr-sm",attrs:{"name":"link"}}),_c('span',{staticClass:"block"},[_vm._v(_vm._s(_vm.$t('opt_step.link_domain_mobile')))])],1)],1)])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }