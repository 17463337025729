
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {QForm} from 'quasar';

import {getSnakedKeyFactory} from '@loopia-group/utils';
import {cloneDeep} from 'lodash-es';
import {maxLength, minLength, validateForm} from '@WS_UIkit';
import WsMessage from '@WS_Components/WsMessage.vue';
import WsButton from '@WS_Components/WsButton.vue';
import WsInput from '@WS_Components/WsInput.vue';
import {CartItemInfo} from '@/store/const';
import {processProps} from '@/utilities';
import {getPropertyDetails} from '@/services/cart/cart-api.service';
import config from '@/services/config.service';
import SidebarLayout from '@/components/SidebarLayout.vue';
import SidebarLayoutItem from '@/components/SidebarLayoutItem.vue';
import PropertiesSummary from '@/components/PropertiesSummary.vue';
import PricePeriod from '@/components/PricePeriod.vue';
import PropertyRadio from './PropertyRadio.vue';
import PropertySelect from './PropertySelect.vue';
import PropertySlider from './PropertySlider.vue';
import PropertyOs from './PropertyOs.vue';

const SELECT_PROPERTIES = ['snapshotSlot'];
const SLIDER_PROPERTIES = ['storage'];

@Component({
  components: {
    QForm,
    WsMessage,
    WsButton,
    WsInput,
    SidebarLayout,
    SidebarLayoutItem,
    PricePeriod,
    PropertiesSummary,
    PropertyRadio,
    PropertySelect,
    PropertySlider,
    PropertyOs,
  },
})
export default class XSellProperties extends Vue {
  @Prop({ required: true })
  readonly properties!: string[];
  @Prop({ default: () => ({}) })
  readonly value!: any;
  @Prop({ default: () => ({}) })
  readonly item!: any;
  @Prop()
  readonly itemSecondary!: any;
  @Prop(Boolean)
  readonly submitLoader!: boolean;

  path = 'xsell';
  options: Record<string, any> = Object.create(null);
  selectProperties = SELECT_PROPERTIES;
  sliderProperties = SLIDER_PROPERTIES;
  config = config;

  links: Record<string, string | undefined> = {
    management: config.managementUrl,
    monitoring: config.monitoringUrl,
  };

  get extraInput() {
    return {
      storage: {
        modelProp: 'propName',
        label: 'product.properties.storage.input_label',
        // TODO: implement support for label & placeholder at same time
        // placeholder: 'product.properties.storage.input_placeholder',
        props: {
          name: 'storage_name',
          path: 'xsell.storage',
          rules: [minLength(3), maxLength(16)],
          restrict: '-\\w',
        },
        hide: !this.model?.storage?.volume,
      },
    };
  }

  get model() {
    return cloneDeep(this.value);
  }

  get itemInfo(): CartItemInfo {
    const item = this.item;
    const info: CartItemInfo = {
      price: {
        ...item.price,
        actualPrice: item.price.vatExcluded,
      },
      period: item.period,
    };

    ['title', 'info', 'no_enhancement'].forEach(
      key => ((info as any)[key] = this.getKeyProduct(item.code, key))
    );

    return info;
  }

  get itemSecondaryAsWinLicence() {
    return this.itemSecondary?.code === 'windows-licence';
  }

  get itemSecondaryInfo(): CartItemInfo | null {
    const itemSecondary = this.itemSecondary;
    if (!this.itemSecondaryAsWinLicence) {
      return null;
    }
    const info: CartItemInfo = {
      price: {
        ...itemSecondary.price,
        actualPrice: itemSecondary.price.vatExcluded,
      },
      period: itemSecondary.period,
    };

    ['title', 'info'].forEach(
      key => ((info as any)[key] = this.getKeyProduct(itemSecondary.code, key))
    );

    return info;
  }

  get code(): string | null {
    return this.item?.code || null;
  }

  get paidOnlyProperties() {
    const period = this.item?.period;
    return this.properties.filter((prop: string) => {
      let price = this.model[prop]?.prices?.find(
        (p: any) => p.period === period
      )?.actualPrice;
      const volume = this.model[prop]?.volume;
      if (volume === 0 && price) {
        price = 0;
      }
      return !!price || price === null;
    });
  }

  get itemProperties() {
    // convert MB properties to adequate units
    return processProps(this.item?.properties, {
      convert: ['ram', 'capacity'],
    });
  }

  get additionalPrice() {
    if (!this.itemSecondaryAsWinLicence) {
      return 0;
    }
    return this.itemSecondary.price.vatExcluded;
  }

  get disabled() {
    return {
      // ref: https://loopiagroup.atlassian.net/browse/PNO-2174
      monitoring: this.model.management?.type === 'advanced',
    };
  }

  originalMonitoringType: null | string = null;
  // ref: https://loopiagroup.atlassian.net/browse/PNO-2174
  @Watch('model.management.type')
  onManagementChanged() {
    if (
      this.model.management?.type === 'advanced' &&
      !this.originalMonitoringType
    ) {
      this.originalMonitoringType = this.model.monitoring?.type;
      let advancedMonitoring = this.options.monitoring?.find(
        (o: any) => o.type === 'advanced'
      );

      if (advancedMonitoring) {
        // cloning not to edit original option
        advancedMonitoring = cloneDeep(advancedMonitoring);
        advancedMonitoring.prices = []; // for free

        this.model.monitoring = advancedMonitoring;
        this.reEmit();
      }
    } else if (this.model.management?.type !== 'advanced') {
      const originalMonitoring = this.options.monitoring?.find(
        (o: any) => o.type === this.originalMonitoringType
      );

      this.originalMonitoringType = null;

      if (originalMonitoring) {
        this.model.monitoring = originalMonitoring;
        this.reEmit();
      }
    }
  }

  @Watch('properties', { immediate: true })
  // eslint-disable-next-line sonarjs/cognitive-complexity
  async onItemChanged(properties: string[]) {
    if (!properties.length) {
      return;
    }

    const results = await Promise.all(
      this.properties.map((prop: string) => {
        let code = this.code!;
        let propOverride: string | null = null;
        const isVdcStorageCase = code === 'vdc' && prop === 'storage';

        if (isVdcStorageCase) {
          // storage is standalone product, we aks for capacity
          code = 'storage';
          propOverride = 'capacity';
        }
        return getPropertyDetails(code, propOverride ?? prop, this.item.id)
          .then((response: any) => {
            const data = response.data;
            if (isVdcStorageCase) {
              data.name = prop;
            }
            if (this.links[prop]) {
              data.link = this.links[prop];
            }
            return data;
          })
          .catch((err: any) => {
            this.$messageService.errorHandler(`xsell.${prop}`)(err);
            return Promise.resolve();
          });
      })
    );

    this.options = Object.create(null); // clear
    results.forEach((result: { name: string; options?: any[] }) => {
      if (!result) {
        // some weird case when result is undefined, did not have time to investigate
        return;
      }
      let { options } = result;
      const { name } = result;

      // https://loopiagroup.atlassian.net/browse/PNO-2363
      if (
        this.item.code === 'vps' &&
        name === 'management' &&
        ['CZ', 'HU'].includes(config.companyCountry) &&
        options
      ) {
        options = options.filter((item: any) => item.type !== 'advanced');
      }

      if (options) {
        this.options[name] = options;
      } else {
        this.model[name] = { ...this.model[name], ...result };
        if (name === 'storage') {
          // handle storage is received in MB
          const model = this.model[name];
          model.min = Math.floor(model.min / 1024);
          model.max = Math.floor(model.max / 1024);
        }
      }
    });

    // exception for automatic backup volume according to storage capacity
    if (this.options.backup) {
      const capacity =
        (this.item.code === 'vdc'
          ? this.model?.storage?.volume
          : this.item?.properties?.capacity) || 0;
      this.options.backup.forEach(
        (option: any) =>
          (option.volume = Math.floor(capacity / 1024)) /* to GB */
      );
    }
  }

  reEmit() {
    this.$emit('input', this.model);
  }

  public async validate() {
    return await validateForm(this.$refs.form as Element & QForm);
  }

  getKeyProduct(prop: string, key: string): string {
    this.getKeyProduct = getSnakedKeyFactory('product');
    return this.getKeyProduct(prop, key);
  }

  getKeyProp(prop: string, key: string): string {
    this.getKeyProp = getSnakedKeyFactory('product.properties');
    return this.getKeyProp(prop, key);
  }
}
