
import { Component, Prop, Vue } from 'vue-property-decorator';
import { VueConstructor } from 'vue';
import { QBtn, QTr, QTd, QTable } from 'quasar';

import { emphasiseQuery } from '@loopia-group/utils';
import WsIcon from '@WS_Components/WsIcon.vue';
import WsButton from '@WS_Components/WsButton.vue';

@Component({
  components: {
    WsIcon,
    WsButton,
    QBtn,
    QTr,
    QTd,
    // fix ref: https://github.com/quasarframework/quasar/issues/6277#issuecomment-652391838
    QTable: (QTable as unknown) as VueConstructor<Vue>,
  },
})
export default class OptionalStepSuggestions extends Vue {
  @Prop({ default: () => [] }) readonly domains!: string[];
  @Prop(String) readonly value!: string;
  @Prop(String) readonly query!: string;
  @Prop(Boolean) readonly loading!: boolean;
  @Prop(Number) readonly selected!: number;

  readonly mobile!: boolean; // global mixin
  readonly tablet!: boolean;
  readonly desktop!: boolean;

  columns = [
    {
      name: 'domain',
      align: 'left',
    },
    {
      name: 'action',
      align: 'right',
    },
  ];

  get pagination() {
    return {
      rowsPerPage: this.mobile ? 3 : 0,
    };
  }

  emit(domain: string) {
    this.$emit('link', domain);
  }

  emphasiseQuery(domain: string): string {
    if (!this.query || !domain) {
      return domain;
    }

    return `<span>${emphasiseQuery(domain, escape(this.query.trim()))}</span>`;
  }
}
