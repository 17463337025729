
import {Component, Prop, Provide, Vue, Watch} from 'vue-property-decorator';
import {Route} from 'vue-router';
import {QForm, QSkeleton, QSpinner, QTooltip} from 'quasar';
import { getPropertyDetails } from '@/services/cart/cart-api.service';
import network from '@/services/network.service';

import {merge} from 'lodash-es';
import {Debounce, getSnakedKeyFactory} from '@loopia-group/utils';

import {addValidations, generalPropertyList, productPropertyList, propertyValidation} from '@WS_UIkit';

import {PropertyValidation} from '@WS_UIkit/types';
import WsMessage from '@WS_Components/WsMessage.vue';
import WsBack from '@WS_Components/WsBack.vue';
import WsButton from '@WS_Components/WsButton.vue';
import WsIcon from '@WS_Components/WsIcon.vue';
import WsInputAdditional from '@WS_Components/WsInputAdditional.vue';

import {Cart, CartItem, StandaloneProduct} from '@/types';
import {safePush, router} from '@/router';
import {ROUTENAMES} from '@/const.enum';
import {StoreActions} from '@/store/const.enum';
import {ensureCartLoaded} from '@/store';
import {logError} from '@/services/logger.service';
import {Domain} from '@/components/domain-checker/dc.types';
import {addToCart, updateItem} from '@/services/cart/cart-api.service';
import {OPTIONAL_STEP_CONTENT} from '@/components/optionalStep/const.enum';
import {checkDomains, stopRunningCheck} from '@/services/domain-checker.service';
import XSellProperties from '@/components/xSell/XSellProperties.vue';
import XSellAlternativeDomains from '@/components/xSell/XSellAlternativeDomains.vue';
import {SuggestionMessage} from '@/components/optionalStep/optionalStep.types.d';
import DomainsResultTable from '@/components/domain-checker/resultsTable/DomainsResultTable.vue';
import OptionalStepMessage from '@/components/optionalStep/OptionalStepMessage.vue';
import OptionalStepSuggestions from '@/components/optionalStep/OptionalStepSuggestions.vue';
import {mapGetters} from 'vuex';
import {useCartItemsStore} from '@/store/cartItemsStore';

const SUPPORTED_MANDATORY_PROPS: string[] = ['name', 'domain', 'eppCode'];
// PNO-2793 no exclusion for SSL, can be cleaned up after successful testing
// const EXCLUDED_FROM_DOMAIN_SEARCH: string[] = ['ssl-single', 'ssl-wildcard'];
const EXCLUDED_FROM_DOMAIN_SEARCH: string[] = [];
const XSELL_PROPS: string[] = [
  'snapshotSlot',
  'storage',
  'backup',
  'monitoring',
  'management',
];
const ALL_SUPPORTED_PROPS: string[] = [
  ...SUPPORTED_MANDATORY_PROPS,
  ...XSELL_PROPS,
];
const XSELL_STANDALONE_PRODUCTS: Record<string, string[]> = {
  vdc: ['storage'],
};
// offering only one result intentionally, according to design
const MAX_DCH_OFFERED_DOMAINS = 1;

const BUSINESS_MAIL_ITEM_CODE: string = 'business-mail';
const ITEM_CODES_WITH_DISABLED_ENTER_SUBMISSION: string[] = [BUSINESS_MAIL_ITEM_CODE];


@Component({
  components: {
    QForm,
    QTooltip,
    QSkeleton,
    QSpinner,
    XSellProperties,
    WsMessage,
    WsBack,
    WsButton,
    WsIcon,
    WsInputAdditional,
    DomainsResultTable,
    OptionalStepMessage,
    OptionalStepSuggestions,
    XSellAlternativeDomains,
  },
  computed: {
    ...mapGetters([
      'cartItemById',
      'loggedIn',
      'dcAllDomains',
    ]),
  },
})
export default class OptionalStep extends Vue {
  @Prop()
  readonly id!: string;
  // eslint-disable-next-line no-unused-vars
  readonly cartItemById!: (id: number) => CartItem;
  readonly loggedIn!: boolean;
  readonly dcAllDomains!: Domain[];
  cartDomains: Domain[] = [];

  MAX_DCH_OFFERED_DOMAINS = MAX_DCH_OFFERED_DOMAINS;
  optionalEditableProps = [] as string[];
  active = false;
  loading = true;
  submitLoader = false;
  addingTransfer = false;
  updatingItem = false;
  selectedOption = 0; // 0 = non selected, so it is 1-based index, 1 is domainsSuggested[0]
  displayValue = ''; // temporary display value for better UX, showing selected value, but do not cause recalculation
  domainCannotBeUsed = ''; // saving last failed domain not to try twice in row
  allowedDomains: string[] = []; // contains the array of the available, user owned domains for the selected product
  readonly mainPropPath = 'optional.main';
  readonly summaryPath = 'optional.summary';
  xSellModel: any = {
    // default preselected values
    monitoring: {
      type: 'empty',
      value: 'false',
    },
    management: {
      type: 'empty',
      value: 'false',
    },
    backup: {
      type: 'empty',
      value: 'false',
    },
    snapshotSlot: {
      type: 'slot_1',
      value: '1',
      prices: [],
    },
    storage: {
      volume: 0,
      nameProp: '',
      saved: false,
      prices: [],
    },
    licences: [],
  };
  OPTIONAL_STEP_CONTENT = OPTIONAL_STEP_CONTENT;

  innerModel = '';
  cartItemsStore = useCartItemsStore();

  mounted() {
    this.getAllowedDomains();
  }

  get allUserDomains(): string[] {
    return this.cartItemsStore.allUserDomains;
  }

  get checkingDomain() {
    return !!this.$store.state.domainChecker.searching;
  }

  set model(model: string) {
    this.innerModel = model;
  }
  get model() {
    return this.domainCase
      ? this.innerModel?.toLowerCase().trim()
      : this.innerModel;
  }

  get mainPropPathFull(): string {
    return this.mainProp
      ? `${this.mainPropPath}.properties.${this.mainProp}`
      : this.mainPropPath;
  }

  @Provide() // provided for DCH cta
  onBoughtDomain(domain: Domain) {
    const domainName = domain.name;
    this.model = domainName;
    this.setProp('domain', domainName);
    this.$messageService.clearMessages({path: 'general'});
  }

  beforeRouteEnter(to: Route, from: Route, next: any) {
    ensureCartLoaded((cart: Cart) => {
      const cartRoute = { name: ROUTENAMES.CART };
      const itemId = parseInt((to.query.item as any) || '');

      const item = itemId
        ? cart.items!.find((i: CartItem) => i.id === itemId)
        : null;
      if (!item) {
        return next(cartRoute);
      }

      const mandatoryProp = getMainProp(item.mandatoryEditableProperties);
      if (
        // forceStay is for testing purpose
        !to.query.forceStay &&
        (!mandatoryProp ||
          ![undefined, null, ''].includes(
            (item?.properties || {})[mandatoryProp]
          ))
      ) {
        return next(cartRoute);
      }
      next();
    });
  }

  get anyLoader(): boolean {
    return (
      this.loading ||
      this.submitLoader ||
      this.checkingDomain ||
      this.addingTransfer ||
      this.updatingItem
    );
  }

  get item(): CartItem | null {
    // @ts-ignore
    const itemId = router?.currentRoute?.query?.item;

    // @ts-ignore
    return itemId ? this.cartItemById?.(parseInt(itemId)) : null;
  }

  get itemSecondary(): CartItem | null {
    // @ts-ignore
    const itemId = router?.currentRoute?.query?.itemSecondary;
    // @ts-ignore
    return itemId ? this.cartItemById?.(parseInt(itemId)) : null;
  }

  get mainMessage() {
    return {
      title: this.getKey('message', 'title'),
      info: this.getKey('message', 'info'),
    };
  }

  getKey(prop: string, key?: string): string {
    if (!this.mainProp || !this.code) {
      return '';
    }
    this.getKey = getSnakedKeyFactory(`opt_step.${this.mainProp}.${this.code}`);
    return this.getKey(prop, key);
  }

  get mainProp(): string | null {
    return getMainProp(this.item?.mandatoryEditableProperties);
  }

  get showMainInputError(): boolean {
    return (
      (this.domainCheckError || this.mainInputHasError) &&
      (!this.newDomainHandling || !this.domainsSuggested?.length)
    );
  }

  get mainInputHasError(): boolean {
    return this.$messageService.hasMessages(this.mainPropPathFull);
  }

  get showQuestion(): boolean {
    return !this.modelEqualsSaved && (!!this.active || !this.displayValue);
  }

  get showXsellAlternatives(): boolean {
    return (
      this.xsellAlternativesAllowedMessage &&
      this.newDomainHandling &&
      !this.equalsToSaved &&
      !this.updatingItem &&
      !!this.contentDebounced &&
      ![
        OPTIONAL_STEP_CONTENT.MESSAGE_HINT,
        OPTIONAL_STEP_CONTENT.MESSAGE_CHECKING,
      ].includes(this.contentDebounced)
    );
  }

  get showXSell(): boolean {
    return !!this.properties.length;
  }

  get propertyValidation(): PropertyValidation {
    if (!this.item || !this.mainProp) {
      return { rules: [], restriction: '' };
    }
    const key = this.domainCase
      ? 'domain'
      : this.item.code + '_' + this.mainProp;
    return propertyValidation(key);
  }

  async filteredOptionalProperties(): Promise<void> {
    if (this.item === null
      || this.item === undefined
      || this.item.optionalEditableProperties === null
      || this.item.optionalEditableProperties === undefined
      || this.item.optionalEditableProperties.length === 0
    ) {
      return;
    }

    const promises = this.item?.optionalEditableProperties
      .map((option) => {
        if (this.item !== null && this.item !== undefined) {
          return getPropertyDetails(this.item.code, option, this.item.id);
        }
      });
    const results = await Promise.all(promises);
    this.optionalEditableProps = results
      .filter((result: any) => result.data.options !== undefined)
      .map((result: any) => result.data.name);

    if (results) {
      this.loading = false;
    }
  }

  get properties(): string[] {
    if(this.item) {
      return [];
    }
    const allProperties = [
      ...this.optionalEditableProps,
      ...((this.item as any)?.mandatoryEditableProperties || []).filter((prop: string) => prop !== this.mainProp),
      // Cross sell standalone products as properties, not actually included in properties
      ...(XSELL_STANDALONE_PRODUCTS[(this.item as any)?.code || ''] || []),
    ];
    return allProperties.filter((prop: string) => ALL_SUPPORTED_PROPS.includes(prop));
  }

  get code(): string | null {
    return this.item?.code || null;
  }

  get domainCase(): boolean {
    return this.mainProp === 'domain';
  }

  // is searching for new domain included in process? yes/no
  get newDomainHandling(): boolean {
    return (
      this.domainCase &&
      !!this.item?.code &&
      // eslint-disable-next-line sonarjs/no-empty-collection
      !EXCLUDED_FROM_DOMAIN_SEARCH.includes(this.item.code)
    );
  }

  /*

    DOMAINS SUGGESTION
    https://www.figma.com/file/JhjUMVHYfFkpdLYqS01rlB/Cart---Carry-redesign?node-id=1299%3A27968

  */

  async getAllowedDomains() {
    if (this.loggedIn) {
      try {
        this.allowedDomains = (await network.get('/users/domains' + '?code=' + this.code)).data.domains;
      } catch (err) {
        this.$root.$messageService.errorHandler('exception.get_allowed_domains.failed')(err);
      }
    }
  }

  // suggested domains (domains user already has referenced somewhere/somehow)
  // not including domains from DCH
  get domains(): string[] {
    // @ts-ignore
    return this.loggedIn ? [...this.allowedDomains, ...this.cartDomains] : this.allUserDomains;
  }

  // suggested domains names filtered by main input model value and domains that user already have
  get domainsSuggested(): string[] {
    return this.model
      ? this.domains.filter((domain: string) => domain.includes(this.model))
      : this.domains;
  }

  get message(): SuggestionMessage | null {
    // @ts-ignore
    if(this.domainsOffered[0]?.error === 'error.domain_checker.not_offered') {
      return {
        prop: 'domain',
        case: OPTIONAL_STEP_CONTENT.DOMAIN_NOT_OFFERED,
      };
    }

    if (!this.mainProp || !this.contentDebounced?.startsWith('message')) {
      return null;
    }

    return {
      prop: this.mainProp,
      case: this.contentDebounced.replace('message-', ''),
    };
  }

  get xsellAlternativesAllowedMessage() {
    if (this.item?.code === BUSINESS_MAIL_ITEM_CODE) {
      switch (this.message?.case) {
      case OPTIONAL_STEP_CONTENT.MESSAGE_HAS_BUSINESS_MAIL:
      case OPTIONAL_STEP_CONTENT.MESSAGE_CURRENT_USER_IS_NOT_THE_OWNER:
      case OPTIONAL_STEP_CONTENT.MESSAGE_IN_WS_LOGGED_OUT:
      case OPTIONAL_STEP_CONTENT.MESSAGE_TRANSFER:
        return true;
      }
    }

    return !this.message;
  }

  /*

    DOMAIN OFFER
    https://www.figma.com/file/JhjUMVHYfFkpdLYqS01rlB/Cart---Carry-redesign?node-id=1299%3A28096

  */

  get checkedDomain(): Domain | null {
    if (!this.newDomainHandling || this.modelIsQuery) {
      return null;
    }

    return this.dcAllDomains?.[0] || null;
  }

  get domainsOffered(): Domain[] {
    if (this.modelIsQuery) {
      const domainsOffered = [
        ...this.dcAllDomains.filter(
          (domain: any) => domain.availableForPurchase && !domain.domainAlreadyInCart
        ),
      ];
      if (domainsOffered.length > MAX_DCH_OFFERED_DOMAINS) {
        domainsOffered.length = MAX_DCH_OFFERED_DOMAINS;
      }
      return domainsOffered;
    } else if (this.checkedDomain) {
      return [this.checkedDomain];
    } else {
      return [];
    }
  }

  get domainCheckError(): String | null {
    const error = this.newDomainHandling && this.checkedDomain?.error;
    return error ? (this.$t(error) as string) : null;
  }

  get available(): boolean {
    return !!this.checkedDomain?.availableForPurchase;
  }

  get savedMainProp(): string | undefined {
    return this.item?.properties?.[this.mainProp || ''];
  }

  get modelEqualsSaved(): boolean {
    return !!this.savedMainProp && this.model === this.savedMainProp;
  }

  // model is just search query if it does not include '.'
  get modelIsQuery(): boolean {
    return this.domainCase && !this.model?.includes?.('.');
  }

  get equalsToSaved(): boolean {
    return (
      this.modelEqualsSaved ||
      (!!this.savedMainProp && this.displayValue === this.savedMainProp)
    );
  }

  // TODO: badly named, better would be "useCase" to be reusalbe elsewhere not just what content is displayed
  // eslint-disable-next-line sonarjs/cognitive-complexity
  get content(): OPTIONAL_STEP_CONTENT | null {
    const domainOffered = this.domainsOffered?.[0];

    switch (true) {
    case !this.model:
      return OPTIONAL_STEP_CONTENT.MESSAGE_HINT;
    case this.equalsToSaved || this.domainCannotBeUsed === this.model:
      return null;
    case !!this.domainsSuggested.length
      && domainOffered:
      return OPTIONAL_STEP_CONTENT.DOMAIN_SUGGESTIONS; // 02-2-1
    case !domainOffered as boolean:
      return this.checkingDomain
        ? OPTIONAL_STEP_CONTENT.MESSAGE_CHECKING
        : null;

      // CHECKED DOMAIN MESSAGES
    case domainOffered!.hasBusinessMail:
      return OPTIONAL_STEP_CONTENT.MESSAGE_HAS_BUSINESS_MAIL;
    case domainOffered!.availableForPurchase &&
        !domainOffered!.domainAlreadyAssigned:
      return OPTIONAL_STEP_CONTENT.DOMAIN_OFFER; // 02-3-1
    case domainOffered!.availableForTransfer:
      return OPTIONAL_STEP_CONTENT.MESSAGE_TRANSFER; // 02-5-1, 02-5-2
    case !this.loggedIn:
      return OPTIONAL_STEP_CONTENT.MESSAGE_IN_WS_LOGGED_OUT; // 02-4-1
    case domainOffered!.domainAlreadyUsedByService === true && domainOffered.isOwnerOfDomain === false:
      return OPTIONAL_STEP_CONTENT.MESSAGE_CURRENT_USER_IS_NOT_THE_OWNER;
    case (this.item && this.item?.code === BUSINESS_MAIL_ITEM_CODE):
      return null;
    case !domainOffered!.error:
      return domainOffered?.domainAlreadyAssigned
        ? OPTIONAL_STEP_CONTENT.MESSAGE_HAS_SERVICE // 02-6-1
        : OPTIONAL_STEP_CONTENT.MESSAGE_OCCUPIED; // 02-6-1 modified

    default:
      return null;
    }
  }
  // flashing removal feature ref: https://loopiagroup.atlassian.net/browse/PNO-2813
  contentDebounced: OPTIONAL_STEP_CONTENT | null = null;
  @Watch('content')
  @Debounce(1000)
  onContentChange(content: OPTIONAL_STEP_CONTENT | null) {
    this.contentDebounced = content;
  }

  @Watch('mainProp')
  @Watch('loggedIn', { immediate: true })
  async onFirstPropChanged() {
    if (!this.mainProp) {
      return;
    }

    if (this.domainCase && this.loggedIn) {
      this.loading = true;
      await this.$store.dispatch(StoreActions.ENSURE_USER_DOMAINS);
    }
    this.loading = false;
  }

  @Watch('model')
  onModelChanged(model: string) {
    // reset selection and displayValue on model changes
    this.selectedOption = 0;
    this.displayValue = '';
    this.clearErrors();

    this.onModelChangedDebounced(model);
  }

  @Debounce(500)
  onModelChangedDebounced(model: string) {
    if (this.newDomainHandling) {
      // potentionally stop check if already running
      stopRunningCheck();
    }

    if (!model) {
      return;
    }

    if (this.newDomainHandling) {
      this.startCheck(model);
    }
  }

  @Watch('selectedOption')
  onSelectedChanged(index: number) {
    if (index) {
      this.displayValue = this.domainsSuggested[this.selectedOption - 1];
    } else {
      this.displayValue = '';
    }
  }

  created() {
    this.filteredOptionalProperties();

    if (this.itemSecondary?.code === 'storage') {
      this.xSellModel.storage.volume =
        this.itemSecondary.properties?.capacity / 1024; // MB to GB;
    }

    if (this.domainCase) {
      this.setMandatoryProp('domain', '');
    }

    addValidations([generalPropertyList, productPropertyList]);
  }

  startCheck = (model: string) => {
    if (!model) {
      return;
    }
    const perPage = this.modelIsQuery ? 100000 /* all */ : 1;
    checkDomains(true, [model], 1, perPage).catch((error: any) => {
      logError(error);
    });
  };

  continueWithoutTransferHandler(newDomain: string): void {
    this.setProp('domain', newDomain);
  }

  submitByEnter() {
    if (ITEM_CODES_WITH_DISABLED_ENTER_SUBMISSION.includes(this.item!.code)) {
      return;
    }

    if (!this.model) {
      return;
    }

    if (!this.newDomainHandling) {
      this.setProp(this.mainProp!, this.model);
      return;
    }

    const useCase = this.contentDebounced;
    if (useCase === OPTIONAL_STEP_CONTENT.DOMAIN_SUGGESTIONS) {
      const value = this.selectedOption
        ? this.domainsSuggested[this.selectedOption - 1]
        : this.model;
      this.setProp('domain', value);
    } else {
      this.setProp(this.mainProp!, this.model);
    }
  }

  xsellAlternativeDomainBoughtHandler(domain: Domain) {
    if (this.item?.code === BUSINESS_MAIL_ITEM_CODE) {
      this.setProp('domain', domain.name);
    }
  }

  async setProp(prop: string, value: string) {
    if (this.item?.properties?.[prop] === value) {
      // value is same as already saved, return
      return;
    }

    if (prop === this.mainProp) {
      this.displayValue = value;
    }

    const mainPropValid: boolean = await (this.$refs
      .mainProp as any)?.validate?.(value);
    if (mainPropValid !== true) {
      this.updatingItem = false;
      return; // error handled by field itself
    }

    return this.setMandatoryProp(prop, value)
      .then(() => {
        ((this.$refs.continueBtn as Vue)?.$el as HTMLElement).focus();
      })
      .catch(this.$messageService.errorHandler('general'));
  }

  async setMandatoryProp(prop: string, value: string): Promise<any> {
    this.updatingItem = true;
    let updateModel = true;

    const item: CartItem = { ...this.item! };
    if (!item.properties) {
      item.properties = {};
    }
    item.properties[prop] = value;

    try {
      await updateItem(item);
    } catch (err) {
      updateModel = this.handleUpdateItemError(err, item, value, updateModel);
    }

    if (updateModel) {
      if (prop === this.mainProp) {
        this.model = value;
      }
      this.domainCannotBeUsed = '';
    }

    this.updatingItem = false;
  }

  handleUpdateItemError(err: any, item: any, value: string, updateModel: boolean) {
    const domainPropError = (err as any).response?.data?.violations?.find(
      (violation: any) => violation.propertyPath === 'properties.domain'
    );

    // usually optional step allows any domain to be set for a product. However, In the case of business mail,
    // this domain gets validated and we would like to offer the user to buy that domain instead of
    // displaying an error message.
    const businessMailDomainNotPurchasedError = item.code === BUSINESS_MAIL_ITEM_CODE
      && domainPropError?.errors?.find((e: any) =>
        e?.translationKey.endsWith('is_not_purchased')
      );

    if (!businessMailDomainNotPurchasedError) {
      const domainCannotBeUsedError = domainPropError?.errors?.find((e: any) =>
        [
          'not_available',
          'not_supported',
          'not_owner',
          'not_owner_of_service',
        ].find(part => e?.translationKey.endsWith(part))
      );

      if (domainCannotBeUsedError) {
        this.domainCannotBeUsed = value;
      }

      this.$messageService.errorHandler(this.mainPropPathFull)(err);
      updateModel = false;
    }
    return updateModel;
  }

  async addTransferToCart(eppCode: string) {
    this.addingTransfer = true;

    const product: StandaloneProduct = {
      code: 'domain-transfer',
      sellType: 'x-sell',
      properties: {
        domain: this.model,
        // period: 12,
        eppCode,
      },
    };

    try {
      await addToCart(product);
      this.setMandatoryProp(this.mainProp!, this.model);
    } catch (err) {
      this.$messageService.errorHandler('general')(err);
    }
    this.addingTransfer = false;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity,max-lines-per-function
  async submit(fromSummary?: true) {
    this.submitLoader = true;

    const mainPropValid: boolean = await (this.$refs
      .mainProp as any)?.validate?.();

    if (mainPropValid !== true) {
      if (this.domainCase === true) {
        this.updatingItem = true;
      } else {
        this.submitLoader = false;
        return; // error handled by field itself
      }
    }

    if (this.$refs.xSell) {
      const xSellValid: boolean = await (this.$refs.xSell as any)?.validate();
      // error handled in xsell component
      if (!xSellValid) {
        this.submitLoader = false;
        return; // error handled by field itself
      }
    }

    const propertiesUpdate: any = {
      [this.mainProp!]: this.model,
    };

    for (const key in this.xSellModel) {
      const val = this.xSellModel[key].value;
      propertiesUpdate[key] =
        val !== undefined
          ? JSON.parse(this.xSellModel[key].value)
          : this.xSellModel[key].volume;
    }

    const errorHandler = this.$messageService.errorHandler(
      fromSummary === true ? this.summaryPath : this.mainPropPathFull
    );

    if (
      this.item?.code === 'vdc' &&
      propertiesUpdate.storage &&
      !this.xSellModel.storage.saved
    ) {
      try {
        const storageProperties = {
          capacity: propertiesUpdate.storage * 1024,
          name: this.xSellModel.storage?.propName,
        };
        if (this.itemSecondary?.code === 'storage') {
          // storage already exist (created on web, provided by url param)
          await updateItem(
            merge({}, this.itemSecondary, {
              properties: storageProperties,
            })
          );
        } else {
          await addToCart({
            code: 'storage',
            sellType: 'direct',
            properties: storageProperties,
          });
        }
        this.xSellModel.storage.saved = true;
      } catch (err) {
        this.submitLoader = false;
        return this.$messageService.errorHandler('xsell.storage')(err);
      }
      delete propertiesUpdate.storage;
    }

    // only if there is an model (user entered value),
    // or cross sell things (not checking updates there for complexity)
    if (this.model || this.displayValue || this.showXSell) {
      try {
        await updateItem(
          merge({}, this.item, { properties: propertiesUpdate })
        );
      } catch (err) {
        this.submitLoader = false;
        return errorHandler(err);
      }
    }
    this.navigateToCart();
  }

  clearErrors() {
    this.$messageService.clearMessages([
      'general',
      this.summaryPath,
      this.mainPropPathFull,
    ]);
  }

  navigateToCart() {
    safePush({ name: ROUTENAMES.CART });
  }

  beforeDestroy() {
    stopRunningCheck();
  }
}

function getMainProp(mandatoryProps: string[] = []): null | string {
  const mainProp = mandatoryProps.find((prop: string) => SUPPORTED_MANDATORY_PROPS.includes(prop));
  return mainProp ?? null;
}
