
import { Component, Vue, Prop } from 'vue-property-decorator';
import { QItem, QItemLabel, QItemSection } from 'quasar';

import { getSnakedKeyFactory } from '@loopia-group/utils';
import WsSelect from '@WS_Components/WsSelect.vue';
import PricePeriod from '@/components/PricePeriod.vue';

@Component({
  components: {
    QItem,
    QItemLabel,
    QItemSection,
    WsSelect,
    PricePeriod,
  },
})
export default class PropertySelect extends Vue {
  @Prop(String)
  readonly path!: string;
  @Prop({ default: () => ({}) })
  readonly value!: any;
  @Prop({ default: () => [] })
  readonly options!: any;
  @Prop({ default: 12 })
  readonly period!: any;

  get model() {
    return { ...this.value };
  }
  set model(model: any) {
    this.$emit('input', model);
  }

  price(opt: any) {
    return (
      opt.prices?.find((p: any) => p.period === this.period)?.actualPrice || 0
    );
  }

  getKey(prop: string, key: string): string {
    this.getKey = getSnakedKeyFactory(this.path);
    return this.getKey(prop, key);
  }
}
