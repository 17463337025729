
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';

import {wsClickOutside} from '@loopia-group/utils';
import {WsInput} from './WsInput.vue';

// implements transparent wrapper component described here: https://github.com/chrisvfritz/7-secret-patterns
// and also some inspiration from: https://zendev.com/2018/05/31/transparent-wrapper-components-in-vue.html
@Component({
  components: {
    WsInput,
  },
  directives: {
    wsClickOutside,
  },
})
export class WsInputAdditional extends Vue {
  @Prop({default: ''}) readonly value!: string | number;
  @Prop(Boolean) readonly preventClose!: boolean;
  @Prop(Boolean) readonly inactive!: boolean; // force inactive

  innerFocused = false;
  hasError = false;
  clickedOutside = false;
  fixTimeout: NodeJS.Timeout | null = null;

  get focused() {
    return this.innerFocused;
  }

  set focused(focused: boolean) {
    if (focused) {
      this.innerFocused = focused;
    } else {
      // setTimeout -> fixes link don't work issue
      // https://loopiagroup.atlassian.net/browse/PNO-2249 https://loopiagroup.atlassian.net/browse/PNO-2166
      // TODO IMHO should be working without setTimeout
      this.fixTimeout = setTimeout(() => {
        this.innerFocused = focused;
      }, 100);
    }
  }

  get active() {
    return (
      (this.focused || (!!this.value && !this.inactive)) &&
      !this.clickedOutside &&
      !this.hasError
    );
  }

  @Watch('active')
  onActiveChanged(active: boolean) {
    this.$emit('active', active);
  }

  @Watch('focused')
  onFocusedChanged(focused: boolean) {
    if (focused) {
      this.clickedOutside = false;
    }
  }

  onClickOutside() {
    if (!this.preventClose) {
      this.clickedOutside = true;
    }
  }

  public validate(val?: any) {
    return (this.$refs.wsInput as WsInput)?.validate(val);
  }

  destroyed() {
    clearTimeout(this.fixTimeout!);
  }
}

export default WsInputAdditional;
