var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.isVisibleDebounced)?_c('div',{staticClass:"cmp-x-sell-alternative-domains",class:{
    'q-px-4xl-b q-py-3xl': _vm.desktop,
    'q-px-2xl q-py-xl': _vm.tablet,
    'q-px-md q-pt-lg q-pb-xl': _vm.mobile,
  }},[_c('h5',{staticClass:"q-mb-lg text-ws-white"},[_vm._v(" "+_vm._s(_vm.$t('xsell.alternative_domains.title'))+" ")]),_c('div',{staticClass:"domains row justify-between items-stretch",class:_vm.mobile ? 'q-col-gutter-sm' : 'q-col-gutter-md',attrs:{"tag":"div","name":"slide-fade-vertical","mode":"out-in","duration":0}},_vm._l((_vm.domains.length ? _vm.domains : _vm.skeletonDomains),function(domain,index){return _c('div',{key:domain.tld || index,staticClass:"domain",class:_vm.mobile ? 'col-6' : `col-${_vm.colWidth}`},[(domain.tld)?_c('div',{staticClass:"content-wrap text-center column justify-center",class:{
          'q-pa-xl': _vm.desktop,
          'q-pa-lg': _vm.tablet,
          'q-px-sm q-py-lg': _vm.mobile,
          disabled: _vm.disabled,
          'adding-to-cart': _vm.addingToCart.has(domain.tld),
        },on:{"click":function($event){return _vm.buy(domain)}}},[_c('span',{staticClass:"domain-title text-ws-white ws-typo-h6 text-lowercase"},[_vm._v("."+_vm._s(domain.tld))]),(_vm.addingToCart.has(domain.tld))?_c('div',{staticClass:"text-center"},[_c('ws-spinner',{attrs:{"height":"24px"}})],1):_c('price-cell',{attrs:{"price":domain.price,"period":domain.period,"prolong-period":domain.prolongPeriod}})],1):_c('q-skeleton',{attrs:{"type":"QRange","animation":"fade","height":"110px"}})],1)}),0),_c('div',{staticClass:"text-center q-mt-2xl"},[_c('ws-button',{attrs:{"secondary":"","flat":"","small":!_vm.desktop,"icon":"arrow_right","icon-right":"","label":"xsell.alternative_domains.link_to_domain_checker"},on:{"click":_vm.goToDomainChecker}})],1),_c('div',{staticClass:"text-center"},[_c('ws-message',{staticClass:"q-mt-md",attrs:{"type":"alert","path":_vm.path}})],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }