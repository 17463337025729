export enum OPTIONAL_STEP_CONTENT {
  MESSAGE_HINT = 'message_hint',
  MESSAGE_CHECKING = 'message_checking',
  MESSAGE_OCCUPIED = 'message_occupied',
  MESSAGE_HAS_SERVICE = 'message_has_service',
  MESSAGE_HAS_BUSINESS_MAIL = 'message_has_business_mail',
  MESSAGE_CURRENT_USER_IS_NOT_THE_OWNER = 'message_current_user_is_not_the_owner',
  MESSAGE_HAS_ACTIVE_POSTFIX_MAILBOXES = 'message_has_active_postfix_mailboxes',
  MESSAGE_IN_WS_LOGGED_OUT = 'message_in_ws_logged_out',
  MESSAGE_TRANSFER = 'message_transfer',
  DOMAIN_SUGGESTIONS = 'domain_suggestions',
  DOMAIN_OFFER = 'domain_offer',
  DOMAIN_NOT_OFFERED = 'not_offered',
}
